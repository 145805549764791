.form_wrapper {
  width: 100%;
  background-color: #fff;
}

.container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 214px;
  max-width: 500px;
  margin: 0 auto;
}

.prosess_error_message {
  color: red;
  font-size: 0.8em;
  margin: 0 auto;
  max-width: 500px;
  border-radius: 5px;
  padding: 8px 0px;
  line-height: 1.4;
  font-weight: 600;
}

.registration {
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
  background: white;
}

.input_row {
  margin-bottom: 10px;
  position: relative;
}


.input_value {
  input {
    background: #fafafa;
    border-radius: 5px;
    box-shadow: 0 0 0 1px #c8c8c8 inset;
    font-size: 16px;
    padding: 12px 12px 10px;
    margin-bottom: 5px;
    width: 100%;

    &:focus {
      box-shadow: 0 0 0 3px #4b6cb7 inset;
    }
  }
}

.input_row.input_has_error {
  .input_value {
    input {
      box-shadow: 0 0 0 3px red inset;
    }
  }
}

.errors {
  color: red;
  font-size: 12.5px;
  font-weight: 700;
  line-height: 1.4;
  display: block;
}

.notes_wrapper {
  display: block;
  font-size: 12px;
}

.submit_btn_wrapper {
  button {
    background: linear-gradient(120deg, rgb(75, 108, 183) 0%, rgb(75, 108, 183) 33%, rgb(46, 64, 99) 66%, rgb(46, 64, 99) 100%);
    border: 3px solid #fff;
    border-radius: 999px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.33);
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 20px;
    font-weight: 900;
    letter-spacing: .1em;
    margin: 0 auto;
    padding: 14px 1em 16px;
    transition: all .2s ease;
    width: calc(100% - 50px);
    text-align: center;

    &:hover {
      box-shadow: 0 1px 2px 0 rgba(0,0,0,.33);
      transform: translateY(2px);
    }

    &:disabled {
      background: #ccc;
      box-shadow: none;
      color: #fff;
      cursor: not-allowed;
      transform: none;
    }
  }
}

.mr_form_finish {
  background: #fafafa;
  border-radius: 5px;
  box-shadow: 0 0 0 2px #4b6cb7 inset;
  margin: 0 auto;
  max-width: 500px;
  padding: 20px;

  h3 {
    align-items: center;
    color: #2e4063;
    display: flex;
    font-size: 17px;
    font-weight: 900;
    justify-content: center;
    margin-bottom: 15px;

    &::before{
      background: url(data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2018%2012%22%3E%3Cpath%20fill%3D%22%232e4063%22%20d%3D%22M16%2012H2a2%202%200%200%201-2-2V2a2%202%200%200%201%20.245-.959L9%209l8.755-7.959A2%202%200%200%201%2018%202v8a2%202%200%200%201-2%202ZM9%207.648.929.311A1.993%201.993%200%200%201%202%200h14a1.993%201.993%200%200%201%201.071.311L9%207.647Z%22%2F%3E%3C%2Fsvg%3E) no-repeat center center;
      content: "";
      display: block;
      height: 12px;
      margin-right: 7px;
      position: relative;
      top: 1px;
      width: 18px;
    }
  }

  p {
    font-size: 13px;
    font-weight: 500;
    margin-top: 15px;
    line-height: 1.6;

    span {
      font-weight: 700;

      &::before {
        content: "※";
        margin-right: 0.25em;
        box-sizing: border-box;
      }
    }

    a {
      background-image: linear-gradient(90deg, #191919, #191919);
      background-position: left bottom;
      background-repeat: no-repeat;
      background-size: 100% 1px;
      transition: background-size .6s;
      word-break: break-all;

      &:hover {
        background-position: right bottom;
        background-size: 0 1px;
      }
    }
  }
}
