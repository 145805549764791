@mixin placeholder($color) {
  &::placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &::-ms-input-placeholder {
    color: $color;
  }
}
@mixin btn-base {
  width: 80%;
  display: block;
  padding: 6px 12px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  font-size: 17px;
  text-align: center;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  &:disabled {
    color: #555;
    background: #ccc;
  }
  .sending {
    display: inline-block;
    overflow: hidden;
    height: 1.3em;
    margin-top: -0.3em;
    line-height: 1.5em;
    vertical-align: text-bottom;
    &::after {
      display: inline-table;
      white-space: pre;
      text-align: left;          
      content: "\A.\A..\A...";
      animation: spin 1.5s steps(4) infinite;
    }
  }
}
$bg_color: #fffdf5;
$base_color1: #3ea757;
$base_color2: #535250;
$font_color1: #333;
$font_color2: #bbb;
$nav_bg_color: #f7f5ed;
$red_color1:  #e83737;

.container {
  position: relative;
  padding: 0;
  margin: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.inner {
  max-width: 540px;
  width: 100%;
  margin: 0 auto;
}

/////////////////////////////////////////////
// Header
/////////////////////////////////////////////

header {
  margin: 30px 0 50px 0;
  padding-bottom: 28px;
  border-bottom: solid 1px $base_color1;
}
.logo_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;

  img {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  span {
    font-size: 13px;
    border: 1px solid #333333;
    border-radius: 16px;
    padding: 0px 10px;
    color: #333333;
    margin: 12px auto 0;
  }
}


/////////////////////////////////////////////
// Main
/////////////////////////////////////////////

h1 {
  text-align: center;
  font-weight: bold;
  margin-bottom: 1em;
  font-size: 1.6em;
}

.wrapper {
  padding-bottom: 50px;
  margin: 0 20px;
  flex-grow: 2;
}
.form_wrapper {
  padding-bottom: 30px;
  background-color: white;
  padding: 30px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 5px;
}
.input_wrapper {
  margin-bottom: 70px;
}
.registration {
  width: 100%;
  margin-bottom: 20px 0px;
}
.policy_wrapper ,
.explanation_wrapper {
  margin: 50px 0;
  p {
    font-size: 90%;
    margin: 0;
  }
  a {
    color: #117ec6;
    &:hover {
      text-decoration: underline;
    }
  }
  ul li{
    position: relative;
    padding: 0 0 0 17px;
    margin: 7px 0 7px 0px;
    font-size: 90%;
    line-height: 1.5;
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 1px;
      height: 0;
      width: 0;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-left: 7px solid #5f615f;
      top: 5px;      
    }
  }
}
.policy_wrapper {
  .text_center {
    text-align: center;
    margin-top: 1.5em;
  }
}

.global_edition_note {
  font-size: 90%;
  max-width: 540px;
  width: 100%;
  margin: 20px auto 0;
  a {
    color: #117ec6;
    &:hover {
      text-decoration: underline;
    }
  }
}

.input_row {
  margin: 0 0 35px 0;
}
.checkbox_container,
.radio_container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
div {
  &.input_title {
    display: block;
    padding: 10px 0 10px 0;
    font-weight: bold;
    letter-spacing: 0.7px;
  }
  &.input_wrap {
    display: flex;
  }
  &.input_col_wrap {
    display: flex;
  }
  &.input_col, &.checkbox_col, .radio_col {
    display: flex;
    align-items: center;
  }
  &.input_col:first-of-type {
    margin-right: 20px;
  }
  &.checkbox_col,
  .radio_col  {
    flex-basis: 50%;
    align-items: flex-start;
    padding: 6px;
  }
  .radio_row  {
    display: flex;
    align-items: center;
    padding: 0px 8px 6px;
  }
  &.checkbox_col_other,
  &.radio_col_other {
    @media screen and (max-width: 450px) {
      flex-direction: column;
      align-items: inherit;
    }
  }
  &.submit_btn_wrapper {
    text-align: center;
    margin: 20px 0px 20px;
    .confirm_btn {
      color: #ffffff;
      background-color: #2bbae4;
      border-color: #46b8da;
      &:hover {
        background-color: darken($color: #2bbae4, $amount: 5%);
      }
      @include btn-base();
      font-weight: bold;
    }
    .submit_btn {
      color: #ffffff;
      background-color: #d9534f;
      border-color: #d9534f;
      &:hover {
        background-color: darken($color: #d9534f, $amount: 5%);
      }
      @include btn-base();
      font-size: 16px;
      font-weight: bold;
    }
    .back_btn {
      color: #ffffff;
      background-color: #999;
      border-color: #ccc;
      &:hover {
        background-color: darken($color: #999, $amount: 5%);
      }
      @include btn-base();
      margin: 30px auto;
    }
    .note {
      margin: 5px;
      font-size: 0.9em;
    }
  }
  &.prosess_error_message {
    color: red;
    font-size: 0.9em;
    margin: 10px 0;
    background-color: #fde8e8;
    border-radius: 5px;
    padding: 14px;
  }
}
div.back_btn_wrapper {
  text-align: center;
  margin: 20px 0px 20px;
  .back_btn {
    color: #ffffff;
    background-color: #999;
    border-color: #ccc;
    &:hover {
      background-color: darken($color: #999, $amount: 5%);
    }
    @include btn-base();
    margin: 30px auto;
}
}

span {
  &.input_label {
    display: block;
    font-size: 90%;
    font-weight: bold;
    letter-spacing: 0.7px;
    padding: 10px 10px 10px 0;
  }
  &.input_value {
    position: relative;
    display: block;
    padding-bottom: 5px;
    input, textarea {
      @include placeholder(#666);
      display: block;
      width: 100%;
      padding: .475rem .75rem;
      font-size: 1.1rem;
      font-weight: 400;
      line-height: 1.5;
      color: $font_color1;
      background-color: #fff;
      background-clip: padding-box;
      border-radius: .25rem;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 0px 1px;
      &:focus {
        box-shadow: rgba($base_color1, 0.7) 0px 0px 0px 2px;
      }
      &:disabled {
        background-color: #ddd;
      }
    }
    textarea {
      height: 6rem;
    }
  }
  &.required_tag {
    margin: 5px;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 20px;
    background-color: #fbdde1;
    color: #c76069;
  }
  &.domain_wrap {
    margin-left: 10px;
    font-size: 1.1em;
    letter-spacing: 0.5px;
  }
  &.notes_wrapper {
    font-size: 90%;
    display: block;
    padding: 0px 3px;
    color: #666;
    a {
      color: #117ec6;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.radio {
  padding: 8px;
  input[type=radio] {
    position: absolute;
    opacity: 0;
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      margin: 7px;
      display: flex;
      align-items: center;
    } 
    & + label:before {
      content: "";
      background: #e6e6e6;
      border-radius: 50%;
      display: inline-block;
      min-width: 1.4em;
      min-height: 1.4em;
      position: relative;
      margin-right: 0.6em;
      vertical-align: middle;
      cursor: pointer;
      text-align: center;
    }
    &:checked + label:before {
      background-color: #3ea757;
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border: 2px solid #3ea757;
    }
  }
}

input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    margin: 7px;
  }  
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: #e6e6e6;
  }
  &:hover + label:before {
    background: $base_color1;
  }
  &:focus + label:before {
    // box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }
  &:checked + label:before {
    background: $base_color1;
  }
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 12px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 
      2px 0 0 white,
      4px 0 0 white,
      4px -2px 0 white,
      4px -4px 0 white,
      4px -6px 0 white,
      4px -8px 0 white;
    transform: rotate(45deg);
  }
}

.other_input_container {
  display: flex;
  flex-direction: row;
}

.select_container {
  position: relative;
  display: inline-block;
  margin-bottom: 5px;
  width: 100%;

  select {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    padding: .475rem .75rem;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $font_color1;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: .25rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 0px 1px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    &::-ms-expand {
      display: none;
    }
    &:focus {
      box-shadow: rgba($base_color1, 0.7) 0px 0px 0px 2px;
    }
    &:disabled{
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
.select_arrow {
  position: absolute;
  top: 18px;
  right: 15px;
  width: 0;
  height: 0;
  pointer-events: none;
  border-style: solid;
  border-width: 10px 6px 0 6px;
  border-color: #7b7b7b transparent transparent transparent;

  .select select:hover ~ &,
  .select select:focus ~ & {
    border-top-color: #000;
  }
  
  .select select:disabled ~ & {
    border-top-color: #ccc;
  }
}

.input_has_error {
  color: $red_color1;
  span.input_value input,
  span.input_value textarea,
  .select_container select {
    box-shadow: rgba($red_color1, 0.7) 0px 0px 0px 2px;
  }
  .errors {
    font-size: 90%;
    display: block;
    padding: 0px 3px;
    color: $red_color1;
  }
}
ul {
  &.nav_menu {
    display: flex;
    width: 100%;
    li {
      width: 31%;
      margin: 0 1%;
      background-color: $nav_bg_color;
      text-align: center;
      padding: 10px 0;
      position: relative;
      letter-spacing: 0.2em;
    }
    li::after ,
    li::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      border-top: 23px solid transparent;
      border-left: 15px solid;
      border-bottom: 23px solid transparent;
    }
    li::after {
      border-left-color: $nav_bg_color;
      z-index: 2;
    }
    li::before {
      border-color: $bg_color;
      z-index: 1;
    }
    a {
      display: block;
    }
    li.nav_active {
      background-color: $base_color1;
      color: #fff;
      font-weight: bold;
    }
    li.nav_active::after {
      border-left-color: $base_color1;
      z-index: 2;
    }
  }
}

.complete {
  .success_message {
    font-size: 1em;
    padding: 15px;
    border: 2px solid $base_color1;
    font-weight: bold;
    color: #3ea757; 
    background-color: #e4f9ea;
    text-align: center;
    margin-bottom: 50px;
  }

  .notice {
    display: flex;
    align-items: center;
    margin: 20px 0;
    .icon_wrapper {
      flex-basis: 80px;
      margin-right: 30px;
      @media screen and (max-width: 550px) {
        display: none;
      }
    }
    .title {
      font-weight: bold;
      font-size: 1.1em;
    }
  }

  ul {
    padding: 15px;
    border: solid 1px #bbb;
  }
}

/////////////////////////////////////////////
// Footer
/////////////////////////////////////////////

footer {
  // width: 100%;
  // position: absolute;
  // bottom: 0;
  padding: 40px 0 40px 0;
  text-align: center;
  background-color: #535250;
  color: #bbb;
  .copyright {
    font-size: 80%;
  }
}


/////////////////////////////////////////////
// Animation
/////////////////////////////////////////////

@keyframes spin  { to { transform: translateY( -6.0em); } }